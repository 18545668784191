import axios from "axios";
import router from "@/router";
import {
  Message
} from 'element-ui'

const base = process.env.VUE_APP_API_ROOT;

axios.defaults.timeout = 1200000;

const matchUrl = ["common/findNeedExcel2", "breeze/getCompanyMsgByTaxNo", "breeze/getProvince",
  "breeze/getIdentityInfo", "breeze/breezeAuthorizationByCode", "breeze/getLoginMethod"
]

// 响应拦截器
axios.interceptors.response.use(response => {
  if (response.status === 200) {
    if (response.data && response.data.code == "5000") {
      router.push({
        name: "login",
        query: {
          redirect: window.location.href
        }
      });
      localStorage.clear();
      sessionStorage.clear();
      return Promise.reject(response);
    } else if (response.data.msgCode != "1") {
      if (response.data.msgInfo) {
        let matchArr = [];
        let res = [];

        for (let i = 0; i < matchUrl.length; i++) {
          matchArr.push(response.config.url.indexOf(matchUrl[i]));
        }
        res = matchArr.filter((num) => {
          return num > -1;
        });
        if (res.length == 0) {
          Message.warning({
            message: response.data.msgInfo
          });
        }
      }
      return Promise.resolve(response);
    } else {
      return Promise.resolve(response);
    }

    //return Promise.resolve(response);
  } else {
    return Promise.reject(response);
  }
});

//全局配置请求头+token
axios.interceptors.request.use(
  config => {
    let token = localStorage.getItem("authorization");
    if(window.location.href.indexOf("fromHouTai") > -1) {
      token = localStorage.getItem("authorizationFromHouTai");
    }
    if(!token) {
      router.push({
        name: "login",
      });
    }
    config.headers.Authorization = `${token}`;
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);


/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params, isAutoWaring = true) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then(res => {
        if (isAutoWaring && res.data.msgCode != "1" && res.data.msgInfo) {
          Message.warning({
            message: res.data.msgInfo
          });
        }
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, isAutoWaring = true, config) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, config)
      .then(res => {
        if (isAutoWaring && res.data.msgCode != "1" && res.data.msgInfo) {
          Message.warning({
            message: res.data.msgInfo
          });
        }
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export default axios;
